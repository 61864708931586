
    import './styles.scoped.css';
    export default {
  "option": "awsui_option_1p2cx_thrdb_149",
  "disabled": "awsui_disabled_1p2cx_thrdb_186",
  "parent": "awsui_parent_1p2cx_thrdb_189",
  "highlighted": "awsui_highlighted_1p2cx_thrdb_192",
  "content": "awsui_content_1p2cx_thrdb_196",
  "label-content": "awsui_label-content_1p2cx_thrdb_203",
  "label": "awsui_label_1p2cx_thrdb_203",
  "tag": "awsui_tag_1p2cx_thrdb_210",
  "label-tag": "awsui_label-tag_1p2cx_thrdb_211",
  "label-prefix": "awsui_label-prefix_1p2cx_thrdb_221",
  "tags": "awsui_tags_1p2cx_thrdb_234",
  "description": "awsui_description_1p2cx_thrdb_235",
  "selected": "awsui_selected_1p2cx_thrdb_246",
  "icon": "awsui_icon_1p2cx_thrdb_260",
  "icon-size-big": "awsui_icon-size-big_1p2cx_thrdb_267",
  "filtering-match-highlight": "awsui_filtering-match-highlight_1p2cx_thrdb_271",
  "trigger-variant": "awsui_trigger-variant_1p2cx_thrdb_286"
};
  