
    import './styles.scoped.css';
    export default {
  "placeholder": "awsui_placeholder_dwuol_b9fx6_141",
  "item": "awsui_item_dwuol_b9fx6_146",
  "checkbox": "awsui_checkbox_dwuol_b9fx6_150",
  "filter": "awsui_filter_dwuol_b9fx6_159",
  "trigger": "awsui_trigger_dwuol_b9fx6_164",
  "layout-strut": "awsui_layout-strut_dwuol_b9fx6_170",
  "list-bottom": "awsui_list-bottom_dwuol_b9fx6_176",
  "selected-icon": "awsui_selected-icon_dwuol_b9fx6_180",
  "show-label-tag": "awsui_show-label-tag_dwuol_b9fx6_184",
  "inline-token-trigger": "awsui_inline-token-trigger_dwuol_b9fx6_188",
  "inline-token-list": "awsui_inline-token-list_dwuol_b9fx6_195",
  "inline-token": "awsui_inline-token_dwuol_b9fx6_188",
  "visual-refresh": "awsui_visual-refresh_dwuol_b9fx6_225",
  "inline-token-hidden-placeholder": "awsui_inline-token-hidden-placeholder_dwuol_b9fx6_232",
  "inline-token-counter": "awsui_inline-token-counter_dwuol_b9fx6_238",
  "inline-token-trigger--disabled": "awsui_inline-token-trigger--disabled_dwuol_b9fx6_242",
  "inline-label-trigger-wrapper": "awsui_inline-label-trigger-wrapper_dwuol_b9fx6_248",
  "inline-label-wrapper": "awsui_inline-label-wrapper_dwuol_b9fx6_252",
  "inline-label": "awsui_inline-label_dwuol_b9fx6_248",
  "inline-label-disabled": "awsui_inline-label-disabled_dwuol_b9fx6_274",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_dwuol_b9fx6_282"
};
  