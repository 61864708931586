
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_9dhru_281",
  "arrow-outer": "awsui_arrow-outer_xjuzf_9dhru_285",
  "arrow-inner": "awsui_arrow-inner_xjuzf_9dhru_285",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_9dhru_327",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_9dhru_327",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_9dhru_330",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_9dhru_330",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_9dhru_333",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_9dhru_333",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_9dhru_333",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_9dhru_333",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_9dhru_336",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_9dhru_336",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_9dhru_336",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_9dhru_336",
  "body": "awsui_body_xjuzf_9dhru_480",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_9dhru_516",
  "has-dismiss": "awsui_has-dismiss_xjuzf_9dhru_520",
  "dismiss": "awsui_dismiss_xjuzf_9dhru_525",
  "dismiss-control": "awsui_dismiss-control_xjuzf_9dhru_533",
  "header-row": "awsui_header-row_xjuzf_9dhru_537",
  "header": "awsui_header_xjuzf_9dhru_537",
  "content": "awsui_content_xjuzf_9dhru_562",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_9dhru_570",
  "container": "awsui_container_xjuzf_9dhru_714",
  "container-body": "awsui_container-body_xjuzf_9dhru_722",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_9dhru_762",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_9dhru_767",
  "fixed-width": "awsui_fixed-width_xjuzf_9dhru_770",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_9dhru_774",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_9dhru_781",
  "container-arrow": "awsui_container-arrow_xjuzf_9dhru_793",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_9dhru_797",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_9dhru_797",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_9dhru_814",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_9dhru_814",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_9dhru_831",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_9dhru_831",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_9dhru_831",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_9dhru_831",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_9dhru_847",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_9dhru_851",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_9dhru_855",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_9dhru_1",
  "refresh": "awsui_refresh_xjuzf_9dhru_1021",
  "root": "awsui_root_xjuzf_9dhru_1043",
  "no-wrap": "awsui_no-wrap_xjuzf_9dhru_1075",
  "root-filtering-token": "awsui_root-filtering-token_xjuzf_9dhru_1079",
  "trigger": "awsui_trigger_xjuzf_9dhru_1083",
  "overflow-ellipsis": "awsui_overflow-ellipsis_xjuzf_9dhru_1090",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_9dhru_1098",
  "trigger-type-filtering-token": "awsui_trigger-type-filtering-token_xjuzf_9dhru_1134",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_9dhru_1138"
};
  