
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_rgq9s_189",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_rgq9s_1",
  "root": "awsui_root_mx3cw_rgq9s_211",
  "hidden": "awsui_hidden_mx3cw_rgq9s_246",
  "with-dismiss": "awsui_with-dismiss_mx3cw_rgq9s_293",
  "with-action": "awsui_with-action_mx3cw_rgq9s_293",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_rgq9s_299",
  "initial-hidden": "awsui_initial-hidden_mx3cw_rgq9s_310",
  "header": "awsui_header_mx3cw_rgq9s_315",
  "header-replacement": "awsui_header-replacement_mx3cw_rgq9s_316",
  "action": "awsui_action_mx3cw_rgq9s_320",
  "action-slot": "awsui_action-slot_mx3cw_rgq9s_325",
  "action-button": "awsui_action-button_mx3cw_rgq9s_326",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_rgq9s_330",
  "text": "awsui_text_mx3cw_rgq9s_359",
  "icon": "awsui_icon_mx3cw_rgq9s_366",
  "message": "awsui_message_mx3cw_rgq9s_369",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_rgq9s_377",
  "icon-size-big": "awsui_icon-size-big_mx3cw_rgq9s_380",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_rgq9s_383",
  "content": "awsui_content_mx3cw_rgq9s_387",
  "content-replacement": "awsui_content-replacement_mx3cw_rgq9s_388",
  "dismiss": "awsui_dismiss_mx3cw_rgq9s_392",
  "dismiss-button": "awsui_dismiss-button_mx3cw_rgq9s_397",
  "type-error": "awsui_type-error_mx3cw_rgq9s_401",
  "type-warning": "awsui_type-warning_mx3cw_rgq9s_409",
  "type-success": "awsui_type-success_mx3cw_rgq9s_417",
  "type-info": "awsui_type-info_mx3cw_rgq9s_425"
};
  