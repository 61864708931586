
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_1aqzc_149",
  "button": "awsui_button_vjswe_1aqzc_153",
  "variant-normal": "awsui_variant-normal_vjswe_1aqzc_201",
  "variant-icon": "awsui_variant-icon_vjswe_1aqzc_244",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_1aqzc_244",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_1aqzc_244",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_1aqzc_265",
  "disabled": "awsui_disabled_vjswe_1aqzc_286",
  "variant-primary": "awsui_variant-primary_vjswe_1aqzc_293",
  "variant-link": "awsui_variant-link_vjswe_1aqzc_385",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_1aqzc_661",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_1aqzc_941",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_1aqzc_1038",
  "button-no-text": "awsui_button-no-text_vjswe_1aqzc_1130",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_1aqzc_1134",
  "full-width": "awsui_full-width_vjswe_1aqzc_1137",
  "icon-left": "awsui_icon-left_vjswe_1aqzc_1156",
  "icon-right": "awsui_icon-right_vjswe_1aqzc_1161",
  "icon": "awsui_icon_vjswe_1aqzc_1156",
  "link": "awsui_link_vjswe_1aqzc_1180",
  "disabled-with-reason": "awsui_disabled-with-reason_vjswe_1aqzc_1180"
};
  