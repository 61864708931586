
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_h6jwt_177",
  "refresh": "awsui_refresh_2qdw9_h6jwt_213",
  "root-no-actions": "awsui_root-no-actions_2qdw9_h6jwt_213",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_h6jwt_216",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_h6jwt_216",
  "root-has-description": "awsui_root-has-description_2qdw9_h6jwt_216",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_h6jwt_225",
  "main": "awsui_main_2qdw9_h6jwt_235",
  "no-wrap": "awsui_no-wrap_2qdw9_h6jwt_245",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_h6jwt_251",
  "actions": "awsui_actions_2qdw9_h6jwt_259",
  "actions-centered": "awsui_actions-centered_2qdw9_h6jwt_264",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_h6jwt_267",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_h6jwt_271",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_h6jwt_275",
  "title": "awsui_title_2qdw9_h6jwt_291",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_h6jwt_296",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_h6jwt_303",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_h6jwt_309",
  "virtual-space": "awsui_virtual-space_2qdw9_h6jwt_330",
  "info": "awsui_info_2qdw9_h6jwt_336",
  "description": "awsui_description_2qdw9_h6jwt_340",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_h6jwt_347",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_h6jwt_351",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_h6jwt_358",
  "heading": "awsui_heading_2qdw9_h6jwt_367",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_h6jwt_378",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_h6jwt_383",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_h6jwt_388",
  "heading-text": "awsui_heading-text_2qdw9_h6jwt_394",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_h6jwt_397",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_h6jwt_405",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_h6jwt_413",
  "counter": "awsui_counter_2qdw9_h6jwt_422"
};
  