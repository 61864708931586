
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_12i0j_1rytq_185",
  "legacy": "awsui_legacy_12i0j_1rytq_200",
  "last-opened": "awsui_last-opened_12i0j_1rytq_208",
  "drawer-global": "awsui_drawer-global_12i0j_1rytq_213",
  "drawer-hidden": "awsui_drawer-hidden_12i0j_1rytq_217",
  "drawer-content-container": "awsui_drawer-content-container_12i0j_1rytq_220",
  "drawer-close-button": "awsui_drawer-close-button_12i0j_1rytq_228",
  "drawer-content": "awsui_drawer-content_12i0j_1rytq_220",
  "drawer-content-hidden": "awsui_drawer-content-hidden_12i0j_1rytq_238",
  "drawer-slider": "awsui_drawer-slider_12i0j_1rytq_241"
};
  