
    import './styles.scoped.css';
    export default {
  "box": "awsui_box_18wu0_19gfc_168",
  "p-variant": "awsui_p-variant_18wu0_19gfc_168",
  "color-default": "awsui_color-default_18wu0_19gfc_168",
  "b-variant": "awsui_b-variant_18wu0_19gfc_168",
  "strong-variant": "awsui_strong-variant_18wu0_19gfc_168",
  "code-variant": "awsui_code-variant_18wu0_19gfc_168",
  "pre-variant": "awsui_pre-variant_18wu0_19gfc_168",
  "samp-variant": "awsui_samp-variant_18wu0_19gfc_168",
  "h1-variant": "awsui_h1-variant_18wu0_19gfc_172",
  "h2-variant": "awsui_h2-variant_18wu0_19gfc_172",
  "h3-variant": "awsui_h3-variant_18wu0_19gfc_172",
  "h4-variant": "awsui_h4-variant_18wu0_19gfc_172",
  "h5-variant": "awsui_h5-variant_18wu0_19gfc_172",
  "small-variant": "awsui_small-variant_18wu0_19gfc_176",
  "a-variant": "awsui_a-variant_18wu0_19gfc_180",
  "font-size-default": "awsui_font-size-default_18wu0_19gfc_184",
  "font-weight-default": "awsui_font-weight-default_18wu0_19gfc_224",
  "key-label-variant": "awsui_key-label-variant_18wu0_19gfc_266",
  "gen-ai-label-variant": "awsui_gen-ai-label-variant_18wu0_19gfc_273",
  "value-large-variant": "awsui_value-large-variant_18wu0_19gfc_280",
  "font-weight-heavy": "awsui_font-weight-heavy_18wu0_19gfc_287",
  "color-inverted": "awsui_color-inverted_18wu0_19gfc_292",
  "color-text-label": "awsui_color-text-label_18wu0_19gfc_295",
  "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_19gfc_298",
  "color-text-status-error": "awsui_color-text-status-error_18wu0_19gfc_301",
  "color-text-status-success": "awsui_color-text-status-success_18wu0_19gfc_304",
  "color-text-status-info": "awsui_color-text-status-info_18wu0_19gfc_307",
  "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_19gfc_310",
  "color-text-status-warning": "awsui_color-text-status-warning_18wu0_19gfc_313",
  "color-inherit": "awsui_color-inherit_18wu0_19gfc_316",
  "font-size-body-s": "awsui_font-size-body-s_18wu0_19gfc_319",
  "font-size-body-m": "awsui_font-size-body-m_18wu0_19gfc_324",
  "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_19gfc_328",
  "font-size-heading-s": "awsui_font-size-heading-s_18wu0_19gfc_332",
  "font-size-heading-m": "awsui_font-size-heading-m_18wu0_19gfc_337",
  "font-size-heading-l": "awsui_font-size-heading-l_18wu0_19gfc_342",
  "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_19gfc_347",
  "font-size-display-l": "awsui_font-size-display-l_18wu0_19gfc_352",
  "font-weight-light": "awsui_font-weight-light_18wu0_19gfc_357",
  "font-weight-normal": "awsui_font-weight-normal_18wu0_19gfc_360",
  "font-weight-bold": "awsui_font-weight-bold_18wu0_19gfc_363",
  "t-left": "awsui_t-left_18wu0_19gfc_370",
  "t-right": "awsui_t-right_18wu0_19gfc_374",
  "t-center": "awsui_t-center_18wu0_19gfc_378",
  "p-n": "awsui_p-n_18wu0_19gfc_395",
  "p-top-n": "awsui_p-top-n_18wu0_19gfc_400",
  "p-vertical-n": "awsui_p-vertical-n_18wu0_19gfc_401",
  "p-right-n": "awsui_p-right-n_18wu0_19gfc_405",
  "p-horizontal-n": "awsui_p-horizontal-n_18wu0_19gfc_406",
  "p-bottom-n": "awsui_p-bottom-n_18wu0_19gfc_410",
  "p-left-n": "awsui_p-left-n_18wu0_19gfc_415",
  "p-xxxs": "awsui_p-xxxs_18wu0_19gfc_420",
  "p-top-xxxs": "awsui_p-top-xxxs_18wu0_19gfc_425",
  "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_19gfc_426",
  "p-right-xxxs": "awsui_p-right-xxxs_18wu0_19gfc_430",
  "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_19gfc_431",
  "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_19gfc_435",
  "p-left-xxxs": "awsui_p-left-xxxs_18wu0_19gfc_440",
  "p-xxs": "awsui_p-xxs_18wu0_19gfc_445",
  "p-top-xxs": "awsui_p-top-xxs_18wu0_19gfc_450",
  "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_19gfc_451",
  "p-right-xxs": "awsui_p-right-xxs_18wu0_19gfc_455",
  "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_19gfc_456",
  "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_19gfc_460",
  "p-left-xxs": "awsui_p-left-xxs_18wu0_19gfc_465",
  "p-xs": "awsui_p-xs_18wu0_19gfc_470",
  "p-top-xs": "awsui_p-top-xs_18wu0_19gfc_475",
  "p-vertical-xs": "awsui_p-vertical-xs_18wu0_19gfc_476",
  "p-right-xs": "awsui_p-right-xs_18wu0_19gfc_480",
  "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_19gfc_481",
  "p-bottom-xs": "awsui_p-bottom-xs_18wu0_19gfc_485",
  "p-left-xs": "awsui_p-left-xs_18wu0_19gfc_490",
  "p-s": "awsui_p-s_18wu0_19gfc_495",
  "p-top-s": "awsui_p-top-s_18wu0_19gfc_500",
  "p-vertical-s": "awsui_p-vertical-s_18wu0_19gfc_501",
  "p-right-s": "awsui_p-right-s_18wu0_19gfc_505",
  "p-horizontal-s": "awsui_p-horizontal-s_18wu0_19gfc_506",
  "p-bottom-s": "awsui_p-bottom-s_18wu0_19gfc_510",
  "p-left-s": "awsui_p-left-s_18wu0_19gfc_515",
  "p-m": "awsui_p-m_18wu0_19gfc_520",
  "p-top-m": "awsui_p-top-m_18wu0_19gfc_525",
  "p-vertical-m": "awsui_p-vertical-m_18wu0_19gfc_526",
  "p-right-m": "awsui_p-right-m_18wu0_19gfc_530",
  "p-horizontal-m": "awsui_p-horizontal-m_18wu0_19gfc_531",
  "p-bottom-m": "awsui_p-bottom-m_18wu0_19gfc_535",
  "p-left-m": "awsui_p-left-m_18wu0_19gfc_540",
  "p-l": "awsui_p-l_18wu0_19gfc_415",
  "p-top-l": "awsui_p-top-l_18wu0_19gfc_550",
  "p-vertical-l": "awsui_p-vertical-l_18wu0_19gfc_551",
  "p-right-l": "awsui_p-right-l_18wu0_19gfc_555",
  "p-horizontal-l": "awsui_p-horizontal-l_18wu0_19gfc_556",
  "p-bottom-l": "awsui_p-bottom-l_18wu0_19gfc_560",
  "p-left-l": "awsui_p-left-l_18wu0_19gfc_565",
  "p-xl": "awsui_p-xl_18wu0_19gfc_570",
  "p-top-xl": "awsui_p-top-xl_18wu0_19gfc_575",
  "p-vertical-xl": "awsui_p-vertical-xl_18wu0_19gfc_576",
  "p-right-xl": "awsui_p-right-xl_18wu0_19gfc_580",
  "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_19gfc_581",
  "p-bottom-xl": "awsui_p-bottom-xl_18wu0_19gfc_585",
  "p-left-xl": "awsui_p-left-xl_18wu0_19gfc_590",
  "p-xxl": "awsui_p-xxl_18wu0_19gfc_595",
  "p-top-xxl": "awsui_p-top-xxl_18wu0_19gfc_600",
  "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_19gfc_601",
  "p-right-xxl": "awsui_p-right-xxl_18wu0_19gfc_605",
  "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_19gfc_606",
  "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_19gfc_610",
  "p-left-xxl": "awsui_p-left-xxl_18wu0_19gfc_615",
  "p-xxxl": "awsui_p-xxxl_18wu0_19gfc_620",
  "p-top-xxxl": "awsui_p-top-xxxl_18wu0_19gfc_625",
  "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_19gfc_626",
  "p-right-xxxl": "awsui_p-right-xxxl_18wu0_19gfc_630",
  "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_19gfc_631",
  "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_19gfc_635",
  "p-left-xxxl": "awsui_p-left-xxxl_18wu0_19gfc_640",
  "m-n": "awsui_m-n_18wu0_19gfc_645",
  "m-top-n": "awsui_m-top-n_18wu0_19gfc_650",
  "m-vertical-n": "awsui_m-vertical-n_18wu0_19gfc_651",
  "m-right-n": "awsui_m-right-n_18wu0_19gfc_655",
  "m-horizontal-n": "awsui_m-horizontal-n_18wu0_19gfc_656",
  "m-bottom-n": "awsui_m-bottom-n_18wu0_19gfc_660",
  "m-left-n": "awsui_m-left-n_18wu0_19gfc_665",
  "m-xxxs": "awsui_m-xxxs_18wu0_19gfc_670",
  "m-top-xxxs": "awsui_m-top-xxxs_18wu0_19gfc_675",
  "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_19gfc_676",
  "m-right-xxxs": "awsui_m-right-xxxs_18wu0_19gfc_680",
  "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_19gfc_681",
  "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_19gfc_685",
  "m-left-xxxs": "awsui_m-left-xxxs_18wu0_19gfc_690",
  "m-xxs": "awsui_m-xxs_18wu0_19gfc_695",
  "m-top-xxs": "awsui_m-top-xxs_18wu0_19gfc_700",
  "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_19gfc_701",
  "m-right-xxs": "awsui_m-right-xxs_18wu0_19gfc_705",
  "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_19gfc_706",
  "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_19gfc_710",
  "m-left-xxs": "awsui_m-left-xxs_18wu0_19gfc_715",
  "m-xs": "awsui_m-xs_18wu0_19gfc_720",
  "m-top-xs": "awsui_m-top-xs_18wu0_19gfc_725",
  "m-vertical-xs": "awsui_m-vertical-xs_18wu0_19gfc_726",
  "m-right-xs": "awsui_m-right-xs_18wu0_19gfc_730",
  "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_19gfc_731",
  "m-bottom-xs": "awsui_m-bottom-xs_18wu0_19gfc_735",
  "m-left-xs": "awsui_m-left-xs_18wu0_19gfc_740",
  "m-s": "awsui_m-s_18wu0_19gfc_745",
  "m-top-s": "awsui_m-top-s_18wu0_19gfc_750",
  "m-vertical-s": "awsui_m-vertical-s_18wu0_19gfc_751",
  "m-right-s": "awsui_m-right-s_18wu0_19gfc_755",
  "m-horizontal-s": "awsui_m-horizontal-s_18wu0_19gfc_756",
  "m-bottom-s": "awsui_m-bottom-s_18wu0_19gfc_760",
  "m-left-s": "awsui_m-left-s_18wu0_19gfc_765",
  "m-m": "awsui_m-m_18wu0_19gfc_770",
  "m-top-m": "awsui_m-top-m_18wu0_19gfc_775",
  "m-vertical-m": "awsui_m-vertical-m_18wu0_19gfc_776",
  "m-right-m": "awsui_m-right-m_18wu0_19gfc_780",
  "m-horizontal-m": "awsui_m-horizontal-m_18wu0_19gfc_781",
  "m-bottom-m": "awsui_m-bottom-m_18wu0_19gfc_785",
  "m-left-m": "awsui_m-left-m_18wu0_19gfc_790",
  "m-l": "awsui_m-l_18wu0_19gfc_665",
  "m-top-l": "awsui_m-top-l_18wu0_19gfc_800",
  "m-vertical-l": "awsui_m-vertical-l_18wu0_19gfc_801",
  "m-right-l": "awsui_m-right-l_18wu0_19gfc_805",
  "m-horizontal-l": "awsui_m-horizontal-l_18wu0_19gfc_806",
  "m-bottom-l": "awsui_m-bottom-l_18wu0_19gfc_810",
  "m-left-l": "awsui_m-left-l_18wu0_19gfc_815",
  "m-xl": "awsui_m-xl_18wu0_19gfc_820",
  "m-top-xl": "awsui_m-top-xl_18wu0_19gfc_825",
  "m-vertical-xl": "awsui_m-vertical-xl_18wu0_19gfc_826",
  "m-right-xl": "awsui_m-right-xl_18wu0_19gfc_830",
  "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_19gfc_831",
  "m-bottom-xl": "awsui_m-bottom-xl_18wu0_19gfc_835",
  "m-left-xl": "awsui_m-left-xl_18wu0_19gfc_840",
  "m-xxl": "awsui_m-xxl_18wu0_19gfc_845",
  "m-top-xxl": "awsui_m-top-xxl_18wu0_19gfc_850",
  "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_19gfc_851",
  "m-right-xxl": "awsui_m-right-xxl_18wu0_19gfc_855",
  "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_19gfc_856",
  "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_19gfc_860",
  "m-left-xxl": "awsui_m-left-xxl_18wu0_19gfc_865",
  "m-xxxl": "awsui_m-xxxl_18wu0_19gfc_870",
  "m-top-xxxl": "awsui_m-top-xxxl_18wu0_19gfc_875",
  "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_19gfc_876",
  "m-right-xxxl": "awsui_m-right-xxxl_18wu0_19gfc_880",
  "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_19gfc_881",
  "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_19gfc_885",
  "m-left-xxxl": "awsui_m-left-xxxl_18wu0_19gfc_890",
  "d-block": "awsui_d-block_18wu0_19gfc_895",
  "d-inline": "awsui_d-inline_18wu0_19gfc_898",
  "d-inline-block": "awsui_d-inline-block_18wu0_19gfc_901",
  "d-none": "awsui_d-none_18wu0_19gfc_904",
  "f-left": "awsui_f-left_18wu0_19gfc_908",
  "f-right": "awsui_f-right_18wu0_19gfc_912",
  "root": "awsui_root_18wu0_19gfc_916"
};
  