
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_tfy6v_185",
  "fit-height": "awsui_fit-height_14iqq_tfy6v_218",
  "with-side-media": "awsui_with-side-media_14iqq_tfy6v_223",
  "variant-default": "awsui_variant-default_14iqq_tfy6v_226",
  "variant-stacked": "awsui_variant-stacked_14iqq_tfy6v_226",
  "refresh": "awsui_refresh_14iqq_tfy6v_234",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_tfy6v_284",
  "with-top-media": "awsui_with-top-media_14iqq_tfy6v_296",
  "content-wrapper": "awsui_content-wrapper_14iqq_tfy6v_301",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_tfy6v_306",
  "media": "awsui_media_14iqq_tfy6v_313",
  "media-top": "awsui_media-top_14iqq_tfy6v_331",
  "media-side": "awsui_media-side_14iqq_tfy6v_336",
  "header": "awsui_header_14iqq_tfy6v_342",
  "header-full-page": "awsui_header-full-page_14iqq_tfy6v_347",
  "header-with-media": "awsui_header-with-media_14iqq_tfy6v_350",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_tfy6v_356",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_tfy6v_360",
  "header-stuck": "awsui_header-stuck_14iqq_tfy6v_366",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_tfy6v_376",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_tfy6v_379",
  "with-paddings": "awsui_with-paddings_14iqq_tfy6v_385",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_tfy6v_394",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_tfy6v_452",
  "header-cover": "awsui_header-cover_14iqq_tfy6v_455",
  "content": "awsui_content_14iqq_tfy6v_301",
  "content-fit-height": "awsui_content-fit-height_14iqq_tfy6v_483",
  "content-inner": "awsui_content-inner_14iqq_tfy6v_489",
  "with-header": "awsui_with-header_14iqq_tfy6v_496",
  "footer": "awsui_footer_14iqq_tfy6v_500",
  "with-divider": "awsui_with-divider_14iqq_tfy6v_504"
};
  