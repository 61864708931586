
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_14yj0_13r5v_141",
  "no-gutters": "awsui_no-gutters_14yj0_13r5v_177",
  "grid-column": "awsui_grid-column_14yj0_13r5v_182",
  "colspan-1": "awsui_colspan-1_14yj0_13r5v_193",
  "push-1": "awsui_push-1_14yj0_13r5v_197",
  "pull-1": "awsui_pull-1_14yj0_13r5v_200",
  "colspan-2": "awsui_colspan-2_14yj0_13r5v_203",
  "push-2": "awsui_push-2_14yj0_13r5v_207",
  "pull-2": "awsui_pull-2_14yj0_13r5v_210",
  "colspan-3": "awsui_colspan-3_14yj0_13r5v_213",
  "push-3": "awsui_push-3_14yj0_13r5v_217",
  "pull-3": "awsui_pull-3_14yj0_13r5v_220",
  "colspan-4": "awsui_colspan-4_14yj0_13r5v_223",
  "push-4": "awsui_push-4_14yj0_13r5v_227",
  "pull-4": "awsui_pull-4_14yj0_13r5v_230",
  "colspan-5": "awsui_colspan-5_14yj0_13r5v_233",
  "push-5": "awsui_push-5_14yj0_13r5v_237",
  "pull-5": "awsui_pull-5_14yj0_13r5v_240",
  "colspan-6": "awsui_colspan-6_14yj0_13r5v_243",
  "push-6": "awsui_push-6_14yj0_13r5v_247",
  "pull-6": "awsui_pull-6_14yj0_13r5v_250",
  "colspan-7": "awsui_colspan-7_14yj0_13r5v_253",
  "push-7": "awsui_push-7_14yj0_13r5v_257",
  "pull-7": "awsui_pull-7_14yj0_13r5v_260",
  "colspan-8": "awsui_colspan-8_14yj0_13r5v_263",
  "push-8": "awsui_push-8_14yj0_13r5v_267",
  "pull-8": "awsui_pull-8_14yj0_13r5v_270",
  "colspan-9": "awsui_colspan-9_14yj0_13r5v_273",
  "push-9": "awsui_push-9_14yj0_13r5v_277",
  "pull-9": "awsui_pull-9_14yj0_13r5v_280",
  "colspan-10": "awsui_colspan-10_14yj0_13r5v_283",
  "push-10": "awsui_push-10_14yj0_13r5v_287",
  "pull-10": "awsui_pull-10_14yj0_13r5v_290",
  "colspan-11": "awsui_colspan-11_14yj0_13r5v_293",
  "push-11": "awsui_push-11_14yj0_13r5v_297",
  "pull-11": "awsui_pull-11_14yj0_13r5v_300",
  "colspan-12": "awsui_colspan-12_14yj0_13r5v_303",
  "push-12": "awsui_push-12_14yj0_13r5v_307",
  "pull-12": "awsui_pull-12_14yj0_13r5v_310",
  "push-0": "awsui_push-0_14yj0_13r5v_313",
  "pull-0": "awsui_pull-0_14yj0_13r5v_316",
  "offset-1": "awsui_offset-1_14yj0_13r5v_319",
  "offset-2": "awsui_offset-2_14yj0_13r5v_322",
  "offset-3": "awsui_offset-3_14yj0_13r5v_325",
  "offset-4": "awsui_offset-4_14yj0_13r5v_328",
  "offset-5": "awsui_offset-5_14yj0_13r5v_331",
  "offset-6": "awsui_offset-6_14yj0_13r5v_334",
  "offset-7": "awsui_offset-7_14yj0_13r5v_337",
  "offset-8": "awsui_offset-8_14yj0_13r5v_340",
  "offset-9": "awsui_offset-9_14yj0_13r5v_343",
  "offset-10": "awsui_offset-10_14yj0_13r5v_346",
  "offset-11": "awsui_offset-11_14yj0_13r5v_349",
  "restore-pointer-events": "awsui_restore-pointer-events_14yj0_13r5v_353"
};
  