
    import './styles.scoped.css';
    export default {
  "button-trigger": "awsui_button-trigger_18eso_1622z_157",
  "arrow": "awsui_arrow_18eso_1622z_157",
  "in-filtering-token-root": "awsui_in-filtering-token-root_18eso_1622z_217",
  "in-filtering-token-nested": "awsui_in-filtering-token-nested_18eso_1622z_217",
  "has-caret": "awsui_has-caret_18eso_1622z_251",
  "placeholder": "awsui_placeholder_18eso_1622z_254",
  "pressed": "awsui_pressed_18eso_1622z_267",
  "disabled": "awsui_disabled_18eso_1622z_270",
  "in-filtering-token": "awsui_in-filtering-token_18eso_1622z_217",
  "readonly": "awsui_readonly_18eso_1622z_286",
  "invalid": "awsui_invalid_18eso_1622z_308",
  "warning": "awsui_warning_18eso_1622z_317",
  "inline-tokens": "awsui_inline-tokens_18eso_1622z_326"
};
  