
    import './styles.scoped.css';
    export default {
  "column-layout": "awsui_column-layout_vvxn7_317l1_177",
  "grid": "awsui_grid_vvxn7_317l1_209",
  "grid-no-gutters": "awsui_grid-no-gutters_vvxn7_317l1_215",
  "grid-variant-text-grid": "awsui_grid-variant-text-grid_vvxn7_317l1_219",
  "grid-breakpoint-default": "awsui_grid-breakpoint-default_vvxn7_317l1_227",
  "grid-columns-1": "awsui_grid-columns-1_vvxn7_317l1_230",
  "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vvxn7_317l1_230",
  "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vvxn7_317l1_233",
  "grid-columns-2": "awsui_grid-columns-2_vvxn7_317l1_236",
  "grid-columns-3": "awsui_grid-columns-3_vvxn7_317l1_242",
  "grid-columns-4": "awsui_grid-columns-4_vvxn7_317l1_248",
  "grid-vertical-borders": "awsui_grid-vertical-borders_vvxn7_317l1_264",
  "grid-horizontal-borders": "awsui_grid-horizontal-borders_vvxn7_317l1_297"
};
  