
    import './styles.scoped.css';
    export default {
  "breadcrumb-group": "awsui_breadcrumb-group_d19fg_106kq_141",
  "breadcrumb-group-list": "awsui_breadcrumb-group-list_d19fg_106kq_176",
  "ghost": "awsui_ghost_d19fg_106kq_187",
  "item": "awsui_item_d19fg_106kq_192",
  "ghost-item": "awsui_ghost-item_d19fg_106kq_193",
  "ellipsis": "awsui_ellipsis_d19fg_106kq_194",
  "hide": "awsui_hide_d19fg_106kq_201",
  "visible": "awsui_visible_d19fg_106kq_207",
  "icon": "awsui_icon_d19fg_106kq_211",
  "breadcrumbs-skeleton": "awsui_breadcrumbs-skeleton_d19fg_106kq_217"
};
  