
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1i0s3_1tu92_141",
  "header": "awsui_header_1i0s3_1tu92_173",
  "content": "awsui_content_1i0s3_1tu92_177",
  "error": "awsui_error_1i0s3_1tu92_181",
  "footer": "awsui_footer_1i0s3_1tu92_185",
  "actions-section": "awsui_actions-section_1i0s3_1tu92_189",
  "secondary-actions": "awsui_secondary-actions_1i0s3_1tu92_198",
  "actions": "awsui_actions_1i0s3_1tu92_189"
};
  